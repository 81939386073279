import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from './utils/web3React'
import { RefreshContextProvider } from './contexts/RefreshContext'
import { ContdownDialogContextProvider } from "./contexts/CountdownContext"
import { ThemeProvider } from '@mui/material'
import theme from './theme'

const Providers: React.FC = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <RefreshContextProvider>
                <ContdownDialogContextProvider>
                    <ThemeProvider theme={theme}>
                        {children}
                    </ThemeProvider>
                </ContdownDialogContextProvider>
            </RefreshContextProvider>
        </Web3ReactProvider>
    )
}

export default Providers