import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    marginLeft: "10px",

    [theme.breakpoints.up("md")]: {
        display: "none"
    },
}))

export const MintButton = styled('button')(({ theme }) => ({
    width: "400px",
    minHeight: "70px",
    background: "url(./images/home/mint.svg) no-repeat",
    cursor: "pointer",
    backgroundSize: "contain",
    backgroundPosition: "left",
    border: "none",
    outline: "none",

    [theme.breakpoints.down("md")]: {
        width: "300px",
    },
}))

export const ConnectButton = styled('button')(({ theme }) => ({
    cursor: "pointer",
    border: "none",
    outline: "none",
    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    width: '130px',
    fontFamily: "Roboto Condensed",
    textTransform: "uppercase",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "15px",
    padding: "10px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.dark,
}))

export const BuyButton = styled('button')(({ theme }) => ({
    background: "linear-gradient(84.31deg, #00FF00 7.77%, rgba(39, 232, 139, 0.814507) 55.63%, rgba(57, 222, 202, 0.73) 96.75%)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 15px",
    width: "250px",
    padding: "0 10px",
    cursor: "pointer",
    border: "none",
    outline: "none",

    [theme.breakpoints.down("md")]: {
        margin: "10px 0px",
    },

    ":disabled": {
        background: theme.palette.background.default,
        cursor: "default",
    }
}))