import React from "react";
import { StyledH2 } from "../../components/Text";
import { Wrapper, WidthWrapper } from "../../components/Layout";
import RoadmapTimeline from "./Timeline";

const Roadmap: React.FC = () => {
    return (
        <div>
            <Wrapper style={{ margin: "10px 0", padding: "0 20px" }}>
                <WidthWrapper>
                    <StyledH2 style={{ margin: "0px" }}>Roadmap</StyledH2>
                </WidthWrapper>
            </Wrapper>

            <RoadmapTimeline />
        </div>
    )
}

export default Roadmap