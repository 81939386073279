import { styled } from "@mui/system";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        display: "block"
    },
}))

export const SnackbarText = styled('p')(({ theme }) => ({
    textTransform: "none",
    margin: "0px",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Roboto Condensed",
    color: theme.palette.secondary.light,
    lineHeight: "25px",

    [theme.breakpoints.down("md")]: {
        fontSize: "16px",
    },
}))

export const SnackbarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    minWidth: "400px",
    height: "80px",
    background: "rgba(30, 33, 39, 0.8)",
    boxShadow: "0 1rem 3rem rgb(0 0 0 / 18%)",

    [theme.breakpoints.down("md")]: {
        minWidth: "330px",
        height: "60px",
    },
}))

export const IconContainer = styled("div")<{ color: string }>(({ color }) => ({
    width: "25px",
    height: "25px",
    margin: "0px 10px",

    "svg": {
        width: "25px",
        height: "25px",
        color: color,
    }
}))

export const CloseButton = styled(IconButton)(({ theme }) => ({
    background: "transparent",
    color: theme.palette.secondary.main,
}))