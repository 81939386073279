import styled from "styled-components";

export const BottomWrapper = styled('div')(({ theme }) => ({
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    boxShadow: "none",
    height: "84px",
    background: "transparent",
    display: "block",
    margin: "10px auto",
}))

export const BottomWidthWrapper = styled('div')<{ isMintButton: boolean }>(({ theme, isMintButton }) => ({
    display: 'flex',
    alignItems: "center",
    justifyContent: `${isMintButton ? "space-between" : "flex-end"}`,
    height: "90px",
    margin: "0 auto",
    width: "99%",

    [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "center",
    },
}))