import styled from "styled-components";
import { AppBar } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import { NavLink } from "react-router-dom";

export const StyledAppBar = styled(AppBar)<{ scroll: string }>(({ theme, scroll }) => ({
    top: 0,
    right: 0,
    left: 0,
    zIndex: 99,
    boxShadow: "none",
    background: `${JSON.parse(scroll) ? theme.palette.background.default : "transparent"}`,
    position: "fixed",
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    height: "84px",
    margin: "0px auto",
    width: "100%",
}))

export const ImgContainer = styled(NavLink)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const StyledLogo = styled('img')(({ theme }) => ({
    width: "100px",
    height: "45px",
}))

export const NavMenu = styled('ul')(({ theme }) => ({
    display: "none",

    [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "end",
        listStyle: "none",
        textAlign: "center",
        justifyContent: "space-between",
        width: "75%",
        margin: "0 10px",
    },
}))

export const NavItem = styled('li')(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: 1.5,
    padding: "10px",
    textTransform: "uppercase",
    fontFamily: "Road Rage",

    ":hover": {
        color: theme.palette.primary.main,
    },
}))

export const DisabledNavItem = styled('li')(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: 1.5,
    padding: "10px",
    textTransform: "uppercase",
    fontFamily: "Road Rage",
    color: theme.palette.secondary.main,
}))

export const NavLinks = styled(NavLink)(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.secondary.dark,
}))

export const CloseIcon = styled.div`
    margin-top: 25px;
    margin-bottom: 20px;
    width:95%;
    display: flex; 
    justify-content: flex-end;
`

export const SidebarContainer = styled('div')<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    position: "fixed",
    zIndex: 100,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.secondary.dark,
    top: 0,
    right: `${isOpen ? '0' : '-100%'}`,
    transition: "0.3s ease-in-out",
    opacity: `${isOpen ? '100%' : '0'}`,

    [theme.breakpoints.up("md")]: {
        display: "none",
    },
}))

export const SidebarWrapper = styled.div(({ theme }) => ({
    color: "#000000",
}))

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 100px);
  text-align: left;
`

export const SidebarLink = styled(NavLink)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: 400,
    display: "flex",
    alignItems: "end",
    justifyContent: "start",
    padding: "0px 60px",
    textTransform: "uppercase",
    textDecoration: "none",
    listStyle: "none",
    color: theme.palette.secondary.light,
    fontFamily: "Road Rage",
    lineHeight: "30px",

    ":hover": {
        color: theme.palette.primary.main,
        transition: "0.2s ease-in-out",
    },
}))

export const DisabledSidebarLink = styled('span')(({ theme }) => ({
    fontSize: "18px",
    fontWeight: 400,
    padding: "0px 60px",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    fontFamily: "Road Rage",
    lineHeight: "30px",
}))


export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`
