import styled from "styled-components"
import { Wrapper, ColumnLayout } from "../../components/Layout"

export const ManifestoBg = styled("div")(({ theme }) => ({
    background: "url(./images/manifesto/manifesto.png) no-repeat",
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",

    [theme.breakpoints.down("md")]: {
        background: "none",
    }
}))

export const IntroContainer = styled('div')(({ theme }) => ({
    width: "100%",
    padding: "20px",
}))

export const CustomWrapper = styled(Wrapper)(({ theme }) => ({
    height: "82.5vh",
    overflowY: "scroll",
    paddingTop: "0px",
    paddingBottom: "0px",

    "::-webkit-scrollbar": {
        display: "none",
    },

    [theme.breakpoints.down("md")]: {
        height: "100%",
        padding: "20px 10px",
    }
}))

export const CustomColumLayout = styled(ColumnLayout)(({ theme }) => ({
    margin: "0px",

    [theme.breakpoints.down("md")]: {
        marginTop: "50px",
    }
}))

export const BoomBg = styled('div')(({ theme }) => ({
    minWidth: "100px",
    maxWidth: "120px",
    background: "url(./images/manifesto/boom.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "45px",
    maxHeight: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))

