import React, { useState, useCallback } from "react";
import { StyledAppBar, StyledToolbar, ImgContainer, StyledLogo, NavMenu, NavItem, NavLinks, DisabledNavItem } from "./elements"
import { navLinks } from "../../config/constants/links";
import { StyledIconButton } from "../Button";
import { HiMenuAlt4 } from "react-icons/hi"
import Sidebar from "./Sidebar";
import { DisabledNavText } from "../Text";
import UnlockButton from "../UnlockButton";
import useScrollNav from "../../hooks/useScrollNav";
import { Box } from "@mui/material";

const NavBar: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const scrollNav = useScrollNav(1);

    const toggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    return (
        <>
            <StyledAppBar scroll={String(scrollNav)}>
                <StyledToolbar>
                    <ImgContainer to="/">
                        <Box sx={{
                            width: "100%",
                            margin: "0px"
                        }}>
                            <StyledLogo src="./images/logo.svg"></StyledLogo>
                        </Box>
                    </ImgContainer>

                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flex: 0.45
                    }}>
                        <NavMenu>
                            {
                                navLinks.map(item => (
                                    <span key={item.name}>
                                        {
                                            item.isAvailable ? <NavLinks exact={true} to={item.link} activeStyle={{ color: "#00FF00" }}>
                                                <NavItem>{item.name}</NavItem>
                                            </NavLinks> : <DisabledNavItem>
                                                {item.name}
                                                <DisabledNavText>soon</DisabledNavText>
                                            </DisabledNavItem>
                                        }
                                    </span>
                                ))
                            }
                        </NavMenu>

                        <UnlockButton />

                        <StyledIconButton size="large" onClick={toggle} color="primary">
                            <HiMenuAlt4 />
                        </StyledIconButton>
                    </Box>
                </StyledToolbar>
            </StyledAppBar>

            <Sidebar isOpen={isOpen} toggle={toggle} />
        </>


    )
}

export default NavBar