import React from "react"
import Dialog from '@mui/material/Dialog';
import { IconButton, Box } from "@mui/material";
import { CardImg } from "../../Card/elements";
import { CardText } from "../../Text";
import { TeamDialogCloseIcon } from "../elements";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { CgClose } from 'react-icons/cg';

interface TeamDialogProps {
    open: boolean,
    onClose: () => void,
    name: string,
    role: string,
    portrait: string,
    description: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TeamDialog: React.FC<TeamDialogProps> = ({ open, onClose, name, role, portrait, description }) => {
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            TransitionComponent={Transition}
            PaperProps={{
                style: { borderRadius: 20 }
            }}
        >
            <TeamDialogCloseIcon>
                <IconButton size="large" onClick={onClose} color="secondary">
                    <CgClose />
                </IconButton>
            </TeamDialogCloseIcon>

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "0 10px"
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "80%",
                }}>
                    <CardText isTitle={true} fontSize="30px" fontWeight={700} lineHeight="56px" margin="5px 0" isUpperCase={true}>{name}</CardText>
                    <CardText isTitle={false} fontSize="20px" fontWeight={300} lineHeight="28px" margin="5px 0" isUpperCase={true}>{role}</CardText>
                </Box>

                <CardImg src={portrait}></CardImg>
                <div style={{ width: "80%" }}>
                    <CardText isTitle={false} fontSize="16px" fontWeight={700} lineHeight="25px" margin="15px 0" dangerouslySetInnerHTML={{ __html: description }}></CardText>
                </div>
            </Box>
        </Dialog>
    )
}

export default TeamDialog