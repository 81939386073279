import styled from "styled-components";

export const HomeBox = styled('div')(({ theme }) => ({
    display: "flex",
}))

export const LogoImage = styled('img')(({
    maxWidth: "300px",
    maxHeight: "200px",
    minHeight: "150px",
    minWidth: "250px",
}))

export const HomeBg = styled("div")(({ theme }) => ({
    background: "url(./images/home/bg.png) no-repeat",
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "right",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    ":hover": {
        background: "url(./images/home/overlay.png) no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right",
        transition: "0.3s ease-in-out",
    },

    [theme.breakpoints.down("md")]: {
        background: "url(./images/home/bgSm.png) no-repeat",
        backgroundSize: "cover",

        ":hover": {
            background: "url(./images/home/bgSm.png) no-repeat",
            backgroundSize: "cover",
        }
    }
}))