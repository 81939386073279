import React, { useRef, useEffect, useState } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { HomeBg, LogoImage } from "./elements";
import CoundownDialog from "../../components/Dialog/CountdownDialog";
import { useFetchBoomgala, useIsWhiteList } from "../../hooks/useFetchBoomgala";
import { ContdownDialogContext } from "../../contexts/CountdownContext"
import { Box, Grid } from "@mui/material";
import { HeroText } from "../../components/Text";
import useHover from "../../hooks/useHover";
import BottomBar from "../../components/BottomBar";

const Home: React.FC = () => {
    const isVisible = useWindowWidth(900);
    const hoverRef = useRef(null)
    const isHover = useHover(hoverRef)
    const salesInfo = useFetchBoomgala()
    const isWhiteList = useIsWhiteList()
    const { openCountdownDialog, setOpenCountdownDialog } = React.useContext(ContdownDialogContext)
    const [userCloseDialog, setUserCloseDialog] = useState<boolean>(false);

    useEffect(() => {
        if (salesInfo) {
            if (salesInfo.publicSaleStarted || salesInfo.wlSaleStarted) {
                setOpenCountdownDialog(false)
            } else {
                if (userCloseDialog) {
                    setOpenCountdownDialog(false)
                } else {
                    setOpenCountdownDialog(true)
                }
            }
        } else {
            setOpenCountdownDialog(false)
        }
    }, [salesInfo, userCloseDialog, setOpenCountdownDialog])

    return (
        <div style={{ overflowY: "hidden" }}>
            <Grid container spacing={0} sx={{ height: "100%" }}>
                <Grid container item xs={0} md={7} alignItems="center" justifyContent="center" >
                    <HomeBg ref={hoverRef}>
                        {
                            isHover && <HeroText >
                                <div style={{ color: "#ffffff" }}>Boom <br />To <br />the <br /></div>future!
                            </HeroText>
                        }
                    </HomeBg>
                </Grid>

                <Grid container item xs={0} md={5} alignItems="center" justifyContent="center">
                    {
                        isVisible && <Box sx={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <LogoImage src="./images/logo.svg"></LogoImage>
                        </Box>
                    }
                </Grid>
            </Grid >

            <BottomBar isMintButton={true} salesInfo={salesInfo} isWhiteList={isWhiteList}></BottomBar>

            {
                openCountdownDialog && <CoundownDialog
                    open={openCountdownDialog}
                    onClose={() => {
                        setOpenCountdownDialog(false);
                        setUserCloseDialog(true)
                    }}
                ></CoundownDialog>
            }
        </div>
    )
}

export default Home