import React from "react"
import { LinkItems } from "./elements"
import { FiExternalLink } from "react-icons/fi"

interface LinkProps {
    href?: string,
    text: string,
}

const LinkEnternal: React.FC<LinkProps> = ({ href, text }) => {
    return (
        <LinkItems href={href} target="_blank">
            <span>{text}</span>
            <FiExternalLink />
        </LinkItems>
    )
}

export default LinkEnternal