import React, { useState } from "react";
import { Box } from "@mui/material";
import { TeamDialogCloseIcon, LogoImage } from "../elements";
import { Dialog } from "@mui/material";
import { IconButton } from "@mui/material";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CountdownCard from "../../Card/CountdownCard";
import { CountdownText } from "../../Text";
import { CgClose } from 'react-icons/cg';
import Countdown from 'react-countdown';

interface CountDownDialogProps {
    open: boolean,
    onClose: () => void
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const renderer = ({ total, days, hours, minutes, seconds }: any) => {
    return (
        <CountdownCard days={days} hours={hours} minutes={minutes} seconds={seconds} />
    );
};

const CoundownDialog: React.FC<CountDownDialogProps> = ({ open, onClose }) => {
    const [startDate] = useState(1645462800000)

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            TransitionComponent={Transition}
            maxWidth="lg"
            PaperProps={{
                style: { borderRadius: 20 }
            }}
        >
            <TeamDialogCloseIcon>
                <IconButton size="large" onClick={onClose} color="secondary">
                    <CgClose />
                </IconButton>
            </TeamDialogCloseIcon>

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "5px 20px",
                width: "100%",
            }}>
                <CountdownText>The Release Party of The</CountdownText>
                <LogoImage src="./images/logo.svg"></LogoImage>
                <Countdown date={startDate} renderer={renderer} />
            </Box>
        </Dialog >
    )
}

export default CoundownDialog