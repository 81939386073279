import React from "react";
import { formatCountdownNumber } from "../../functions/format";
import { CardText } from "../Text";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { CountDownCardBox } from "./elements"
import useWindowWidth from "../../hooks/useWindowWidth";
import { Divider } from '@mui/material';

interface CountdownCardProps {
    days: string,
    hours: string,
    minutes: string,
    seconds: string
}

const CountdownCard: React.FC<CountdownCardProps> = ({ days, hours, minutes, seconds }) => {
    const isVisible = useWindowWidth(900);
    return (
        <CountDownCardBox>
            <Grid container spacing={2} columns={15}>
                <Grid container item xs={15} md={3} alignItems="center" justifyContent="center">
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}>
                        <CardText fontSize="70px" fontWeight={700} isTitle={true} margin="10px 0" lineHeight="80px" style={{ color: "#ffffff" }}>{formatCountdownNumber(days)}</CardText>
                        <CardText fontSize="16px" fontWeight={700} isTitle={false} margin="10px 0" lineHeight="18px" isUpperCase={true} style={{ color: "#9F9F9F" }}>days</CardText>
                        {!isVisible && <Divider sx={{ minWidth: "200px", backgroundColor: "#9F9F9F", marginTop: "20px" }}></Divider>}
                    </Box>
                </Grid>

                {
                    isVisible && <Grid container item xs={0} md={1} alignItems="start" justifyContent="center">
                        <CardText fontSize="25px" fontWeight={700} isTitle={false} margin="10px 0" lineHeight="80px" style={{ color: "#9F9F9F" }}>:</CardText>
                    </Grid>
                }

                <Grid container item xs={3} md={3} alignItems="center" justifyContent="center">
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}>
                        <CardText fontSize="70px" fontWeight={700} isTitle={true} margin="10px 0" lineHeight="80px" style={{ color: "#ffffff" }}>{formatCountdownNumber(hours)}</CardText>
                        <CardText fontSize="16px" fontWeight={700} isTitle={false} margin="10px 0" lineHeight="18px" isUpperCase={true} style={{ color: "#9F9F9F" }}>hours</CardText>
                    </Box>
                </Grid>

                <Grid container item xs={3} md={1} alignItems="start" justifyContent="center">
                    <CardText fontSize="25px" fontWeight={700} isTitle={false} margin="10px 0" lineHeight="80px" style={{ color: "#9F9F9F" }}>:</CardText>
                </Grid>

                <Grid container item xs={3} md={3} alignItems="center" justifyContent="center">
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}>
                        <CardText fontSize="70px" fontWeight={700} isTitle={true} margin="10px 0" lineHeight="80px" style={{ color: "#ffffff" }}>{formatCountdownNumber(minutes)}</CardText>
                        <CardText fontSize="16px" fontWeight={700} isTitle={false} margin="10px 0" lineHeight="18px" isUpperCase={true} style={{ color: "#9F9F9F" }}>minutes</CardText>
                    </Box>
                </Grid>

                <Grid container item xs={3} md={1} alignItems="start" justifyContent="center">
                    <CardText fontSize="25px" fontWeight={700} isTitle={false} margin="10px 0" lineHeight="80px" style={{ color: "#9F9F9F" }}>:</CardText>
                </Grid>

                <Grid container item xs={3} md={3} alignItems="center" justifyContent="center">
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}>
                        <CardText fontSize="70px" fontWeight={700} isTitle={true} margin="10px 0" lineHeight="80px" style={{ color: "#ffffff" }}>{formatCountdownNumber(seconds)}</CardText>
                        <CardText fontSize="16px" fontWeight={700} isTitle={false} margin="10px 0" lineHeight="18px" isUpperCase={true} style={{ color: "#9F9F9F" }}>seconds</CardText>
                    </Box>
                </Grid>
            </Grid>
        </CountDownCardBox >
    )
}

export default CountdownCard