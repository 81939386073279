import styled from "styled-components";

export const SocialIconBox = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
        justifyContent: "start",
    },
}))

export const SocialImg = styled('img')(({ theme }) => ({
    width: "30px",
    height: "30px",

    [theme.breakpoints.down("md")]: {
    },
}))