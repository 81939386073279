import React, { useCallback, useState } from "react";
import { WidthWrapper, Wrapper, ColumnLayout } from "../../components/Layout";
import BottomBar from "../../components/BottomBar";
import { Grid, Box } from "@mui/material";
import TeamCard from "../../components/Card/TeamCard";
import useWindowWidth from "../../hooks/useWindowWidth";
import { StyledH2, CardText } from "../../components/Text";
import teams from "../../config/constants/teams";
import { TeamConfig } from "../../config/types";
import { TeamImg, CustomColumLayout } from "./elements";
import TeamDialog from "../../components/Dialog/TeamDialog";

const Team: React.FC = () => {
    const isVisible = useWindowWidth(900)
    const [teamMember, setTeamMember] = useState<TeamConfig>(teams[0])
    const [open, setOpen] = useState<boolean>(false)

    const selectTeamMember = useCallback((id) => {
        if (isVisible) {
            const [selected] = teams.filter(team => team.index === id);
            setTeamMember(selected);
        }
    }, [isVisible])

    const handleTeamMember = useCallback((id) => {
        if (!isVisible) {
            setOpen(true);
            const [selected] = teams.filter(team => team.index === id);
            setTeamMember(selected);
        }
    }, [isVisible])

    return (
        <>
            <Wrapper>
                <WidthWrapper>
                    <ColumnLayout></ColumnLayout>
                    <Grid container spacing={5}>
                        {isVisible && <Grid container item md={12} lg={6} alignItems="center" justifyContent="center">
                            <TeamCard
                                name={teamMember.name}
                                portrait={teamMember.portrait}
                                role={teamMember.role}
                                description={teamMember.description}
                            />
                        </Grid>}

                        <Grid container item md={12} lg={6} alignItems="center">
                            <CustomColumLayout>
                                <StyledH2>Created By Many</StyledH2>
                                <CardText isTitle={false} fontSize="20px" fontWeight={400} lineHeight="30px" margin="5px 0">
                                    We are a team of artists, creators and practitioners. Our members come from the UK, Australia, China and Hong Kong SAR; BTC miners with 10 years' experience, The Sandbox landowners, talented artists, visual arts creators, marketing experts with extensive industry experience etc.
                                </CardText>

                                <Grid container spacing={6} sx={{ marginTop: "0px" }}>
                                    {
                                        teams.map(team => (
                                            <Grid container item xs={6} sm={6} md={4} key={team.index} alignItems="center" justifyContent="center" style={{ paddingTop: "0px", marginTop: "10px", }}>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                }}>
                                                    <TeamImg src={team.portrait} onMouseEnter={() => selectTeamMember(team.index)} onClick={() => handleTeamMember(team.index)}></TeamImg>

                                                    <CardText isTitle={false} fontSize="20px" fontWeight={700} lineHeight="15px" margin="0 0 5px 0">{team.name}</CardText>
                                                    <CardText isTitle={false} fontSize="16px" fontWeight={300} lineHeight="16px" margin="5px 0">{team.role}</CardText>
                                                </Box>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </CustomColumLayout>
                        </Grid>
                    </Grid>
                </WidthWrapper>
            </Wrapper >

            <BottomBar isMintButton={false}></BottomBar>

            <TeamDialog
                open={open}
                onClose={() => setOpen(false)}
                name={teamMember.name}
                portrait={teamMember.portrait}
                role={teamMember.role}
                description={teamMember.description}
            ></TeamDialog>
        </>
    )
}

export default Team

