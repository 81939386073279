import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00FF00',
        },
        secondary: {
            main: "#B5B5B5",
            light: "#ffffff",
            dark: "#000000"
        },
        background: {
            default: '#e8e8e8',
        }
    },
});

export default theme