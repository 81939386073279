import { useMemo } from "react"
import useActiveWeb3React from "./useActiveWeb3React"
import { ethers } from "ethers"
import { Contract } from '@ethersproject/contracts'
import { getContract } from "../utils/contractHelpers"
import { getBoomgalaAddress } from "../utils/addressHelpers"
import BOOMGALA_ABI from "../config/abis/boomgala.json"

const chainId = Number(process.env.REACT_APP_CHAIN_ID)

export function useContract(
    address: string | undefined,
    ABI: ethers.ContractInterface,
    withSignerIfPossible = true
): Contract | null {
    const { library, account } = useActiveWeb3React()

    return useMemo(() => {
        if (!address || !ABI || !library) return null
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, library, withSignerIfPossible, account])
}

export const useBoomgalaContract = (withSignerIfPossible?: boolean) => {
    return useContract(getBoomgalaAddress(chainId), BOOMGALA_ABI, withSignerIfPossible)
}