import React from "react"
import { navLinks } from "../../config/constants/links";
import {
    SidebarContainer,
    SidebarWrapper,
    SidebarLink,
    SidebarMenu,
    CloseIcon,
    DisabledSidebarLink
} from './elements';
import { DisabledNavText } from "../Text";
import { StyledIconButton } from "../Button";
import SocialIcons from "../SocialIcons";
import { CgClose } from 'react-icons/cg';
import { Box } from "@mui/system";

interface SidebarProps {
    isOpen: boolean,
    toggle: () => void,
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen}>
            <CloseIcon>
                <StyledIconButton size="large" onClick={toggle} color="secondary">
                    <CgClose />
                </StyledIconButton>
            </CloseIcon>

            <SidebarWrapper>
                <SidebarMenu>
                    {
                        navLinks.map(item => (
                            <span key={item.name} >
                                {
                                    item.isAvailable
                                        ? <SidebarLink to={item.link} onClick={toggle} exact={true} activeStyle={{ color: "#00FF00" }}>{item.name}</SidebarLink>
                                        : <DisabledSidebarLink>
                                            {item.name}
                                            <DisabledNavText style={{ color: "#ffffff" }}>Soon</DisabledNavText>
                                        </DisabledSidebarLink>
                                }
                            </span>

                        ))
                    }
                </SidebarMenu>

                <Box sx={{ padding: "0 45px" }}>
                    <SocialIcons />
                </Box>

            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar