import { TeamConfig } from "../types";

const teams: TeamConfig[] = [
    {
        index: "001",
        name: "Soy",
        portrait: "./images/team/soy.jpg",
        description: "Well, he is a fat man like soybean that guided us all the way to the earth.",
        role: "Miner",
    },
    {
        index: "002",
        name: "0xnova",
        portrait: "./images/team/Daniel.jpg",
        description: "Finance and Management expert, all the best for the BOOM of community!",
        role: "Manager",
    },
    {
        index: "003",
        name: "BCIII",
        portrait: "./images/team/BCIII.jpg",
        description: "Look at your body, you need to be given a new avatar to integrate into the metaverse.",
        role: "Artist"
    },
    {
        index: "004",
        name: "J9000",
        portrait: "./images/team/James.jpg",
        description: "Party expert. <br/> Here to build a stylish party of BOOMGALA!",
        role: "Creative Director",
    },
    {
        index: "005",
        name: "Mikeverse13",
        portrait: "./images/team/Mikeverse.jpg",
        description: "Smart contracts and Sandbox development. Leading expert of virtual world, guardian and communicator of community.",
        role: "Tech Guy",
    },
    {
        index: "006",
        name: "Sooga",
        portrait: "./images/team/Sooga.jpg",
        description: "World Top design academy, from architecture to interactive design, break boundaries and beyond limits.",
        role: "Designer",
    },
]

export default teams;