import styled from "styled-components";

export const CloseIcon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background:transparent;
    border:none;
    cursor: pointer;
    outline: none;
`
export const TeamDialogCloseIcon = styled(CloseIcon)`
    top: 0px;
    right: 0px;
`

export const MintImg = styled('img')(({ theme }) => ({
    width: "65%",

    [theme.breakpoints.between("md", "lg")]: {
        width: "90%",
    },
}))

export const MintIcon = styled('img')(({ theme }) => ({
    width: "40px",
    height: "40px",
    marginRight: "20px",
}))

export const PriceBg = styled('div')<{ width: string }>(({ theme, width }) => ({
    filter: "drop-shadow(2px 0px 10px #00FF00)",
    background: theme.palette.primary.main,
    width,
    height: "40px",
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    margin: "0 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700
}))

export const LogoImage = styled('img')(({ theme }) => ({
    width: "200px",
    height: "95px",
    transform: "translate(0%, -50%);",

    [theme.breakpoints.down("md")]: {
        width: "105px",
        height: "50px",
    },
}))



