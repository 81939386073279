import { useEffect, useState, useCallback } from "react";
import multicall from "../utils/multicall";
import { getBoomgalaAddress } from "../utils/addressHelpers";
import { BoomgalaSalesInfo } from "./types";
import useActiveWeb3React from "./useActiveWeb3React";
import useRefresh from "./useRefresh";
import BOOMGALA_ABI from "../config/abis/boomgala.json"
import { getBalanceNumber } from "../utils/format"
import BigNumber from "bignumber.js";
import { useBoomgalaContract } from "./useContract";

const chainId = Number(process.env.REACT_APP_CHAIN_ID)

export const useFetchBoomgala = () => {
    const { slowRefresh } = useRefresh();
    const [boomgalaSalesInfo, setBoomgalaSalesInfo] = useState<BoomgalaSalesInfo>()

    const fetchInfo = useCallback(async () => {
        const call = [
            {
                address: getBoomgalaAddress(chainId),
                name: 'saleConfig',
            },

            {
                address: getBoomgalaAddress(chainId),
                name: 'totalSupply',
            },
        ]

        try {
            const results = await multicall(BOOMGALA_ABI, call)

            setBoomgalaSalesInfo({
                wlSaleStarted: results[0]["wlSaleStarted"],
                publicSaleStarted: results[0]["publicSaleStarted"],
                wlPrice: getBalanceNumber(new BigNumber(results[0]["wlPrice"].toString())),
                wlMaxAmount: results[0]["wlMaxAmount"].toNumber(),
                publicPrice: getBalanceNumber(new BigNumber(results[0]["publicPrice"].toString())),
                publicMaxAmount: results[0]["publicMaxAmount"].toNumber(),
                totalSupply: results[1][0].toNumber()
            })
        } catch (err) {
            console.error("Fail to fetch sales info for boomgala", err)
        }
    }, [])

    useEffect(() => {
        let mounted = true;
        if (mounted === true) {
            fetchInfo()
        }
        return () => { mounted = false }
    }, [slowRefresh, fetchInfo])

    return boomgalaSalesInfo
}

export const useIsWhiteList = () => {
    const [isWhiteList, setIsWhiteList] = useState<boolean>()
    const { account } = useActiveWeb3React()
    const boomgalaContract = useBoomgalaContract()

    const fetchInfo = useCallback(async () => {
        try {
            const result = await boomgalaContract?.allowList(account)
            setIsWhiteList(result)

        } catch (err) {
            console.error("Fail to know whether this account is whitelisted", err)
        }
    }, [account, boomgalaContract])

    useEffect(() => {
        let mounted = true;
        if (mounted === true && account) {
            fetchInfo()
        }
        return () => { mounted = false }
    }, [fetchInfo, account])

    return isWhiteList
}

