import React, { useState, useCallback } from "react";
import SocialIcons from "../SocialIcons";
import { MintButton } from "../Button";
import MintDialog from "../Dialog/MintDialog";
import useWindowWidth from "../../hooks/useWindowWidth";
import CoundownDialog from "../Dialog/CountdownDialog";
import { ContdownDialogContext } from "../../contexts/CountdownContext"
import { BoomgalaSalesInfo } from "../../hooks/types";
import CustomSnackbar from "../Snackbar";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { BottomWrapper, BottomWidthWrapper } from './elements'

interface BottomBarProps {
    isMintButton: boolean,
    salesInfo?: BoomgalaSalesInfo,
    isWhiteList?: boolean
}

const BottomBar: React.FC<BottomBarProps> = ({ isMintButton, salesInfo, isWhiteList }) => {
    const isVisible = useWindowWidth(900)
    const [openMintDialog, setOpenMintDialog] = useState<boolean>(false)
    const { openCountdownDialog, setOpenCountdownDialog } = React.useContext(ContdownDialogContext)
    const { account } = useActiveWeb3React()
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("");
    const wlSaleStarted = salesInfo?.wlSaleStarted;
    const publicSaleStarted = salesInfo?.publicSaleStarted;

    const handleOpen = useCallback(() => {
        if (account) {
            if (wlSaleStarted || publicSaleStarted) {
                setOpenMintDialog(true)
                setOpenCountdownDialog(false)
            } else {
                setOpenCountdownDialog(true)
                setOpenMintDialog(false)
            }
        } else {
            setOpenSnackbar(true)
            setErrorMessage("Please connect wallet first")
        }
    }, [account, wlSaleStarted, publicSaleStarted, setOpenCountdownDialog])

    return (
        <>
            <BottomWrapper>
                <BottomWidthWrapper isMintButton={isMintButton}>
                    {isMintButton && <MintButton onClick={() => handleOpen()} />}
                    {isVisible && <SocialIcons />}
                </BottomWidthWrapper>
            </BottomWrapper >

            <MintDialog
                open={openMintDialog}
                onClose={() => setOpenMintDialog(false)}
                salesInfo={salesInfo}
                isWhiteList={isWhiteList}
            ></MintDialog>

            <CoundownDialog
                open={openCountdownDialog}
                onClose={() => setOpenCountdownDialog(false)}
            ></CoundownDialog>

            <CustomSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                error={true}
                errorMessage={errorMessage}
            ></CustomSnackbar>
        </>
    )
}

export default BottomBar

