import React from "react";
import { StyledH2 } from "../../components/Text";
import { CardText } from "../../components/Text";
import { IntroContainer } from "./elements";

const Intro: React.FC = () => {
    return (
        <IntroContainer>
            <StyledH2>About</StyledH2>
            <CardText isTitle={true} isUpperCase={true} fontSize="35px" margin="0 0 30px 0" fontWeight={700} lineHeight="20px" style={{ color: "#000", letterSpacing: "4px" }}>Boomgala</CardText>
            <CardText isTitle={false} isUpperCase={false} fontSize="18px" margin="10px 0" fontWeight={400} lineHeight="30px">
                BOOMGALA is an original project run on a community basis connected through the value structure of the metaverse.
                <br />
                <br />
                We are a team of artists, creators and actors. We have members from the UK, Australia, China and Hong Kong SAR; BTC miners with 10 years experience, the sandbox landlords, talented artists, visual art creators, marketing experts with extensive industry experience etc.
                <br />
                <br />
                We are all Crypto natives and with the same ideology, a shared vision of the Metaverse and the spirit of blockchain and DAO, we started the BOOMGALA project with the hope of building a metaverse society with a complete ecology for everyone in the sandbox.
                <br />
                <br />
                A place where we connect values, develop, create, explore with the community, embrace uncharted territory and break through the imagination.
            </CardText>
        </IntroContainer>
    )
}

export default Intro