import { RoadmapConfig } from "../types";

const roadmapContents: RoadmapConfig[] = [
    {
        index: "01",
        title: "METAVERSE",
        desc: "In the BOOMGALA metaverse, we develop games, casinos, art spaces, etc., to reach a complete ecology that grows organically.",
        icon: "./images/manifesto/metaverse.svg"
    },

    {
        index: "02",
        title: "STREETWEAR",
        desc: "Bringing the spirit of BOOMGALA back to the real world in the form of streetwear, with artifacts, souvenirs, and offline events, creating a multi-dimensional connection.",
        icon: "./images/manifesto/streetwear.svg"
    },

    {
        index: "03",
        title: "ASSETS",
        desc: "We will continue to acquire assets, optimize the income of the metaverse, develop, incubate and invest in new projects to reach a positive capital cycle and maximize the value of creativity.",
        icon: "./images/manifesto/assets.svg"
    },
]

export default roadmapContents;