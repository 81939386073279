import { useState, useCallback } from "react"
import useEventListener from "./useEventListener"

const useScrollNav = (scrollY: number) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = useCallback(() => {
        if (window.scrollY >= scrollY) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }, [scrollY])

    useEventListener('scroll', changeNav)

    return scrollNav
}

export default useScrollNav