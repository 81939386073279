import * as React from 'react';
import {
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot
} from '@mui/lab';

import { CardText } from '../../components/Text';
import { Box } from '@mui/system';
import { StyledTimelineItem, RoadmapContainer, StyledTimeline, RoadmapIcon } from './elements';
import roadmapContents from '../../config/constants/roadmap';

const RoadmapTimeline: React.FC = () => {
    return (
        <RoadmapContainer>
            <StyledTimeline>
                {
                    roadmapContents.map((item) => (
                        <StyledTimelineItem key={item.index}>
                            <TimelineSeparator>
                                <TimelineDot color='primary' sx={{ width: "16px", height: "16px" }}></TimelineDot>
                                <TimelineConnector sx={{ bgcolor: "#00FF00" }} />
                            </TimelineSeparator>

                            <TimelineContent sx={{ width: "100%", marginBottom: "10px" }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: "start",
                                    justifyContent: "cnter",
                                    margin: "5px 0 20px 0"
                                }}>
                                    <RoadmapIcon src={item.icon}></RoadmapIcon>
                                    <CardText isTitle={true} isUpperCase={true} fontSize="24px" margin="0 5px" fontWeight={700} lineHeight="20px" style={{ color: "#000" }}>{item.title}</CardText>
                                </Box>
                                <CardText isTitle={false} isUpperCase={false} fontSize="16px" margin="0px" fontWeight={400} lineHeight="25px">{item.desc}</CardText>
                            </TimelineContent>
                        </StyledTimelineItem >
                    ))
                }
            </StyledTimeline >
        </RoadmapContainer>
    );
}

export default RoadmapTimeline

