import styled from 'styled-components';

export const StyledIconLink = styled("a")(({ theme }) => ({
    width: "40px",
    height: "40px",
    margin: "0 10px",
    boxShadow: "inset 0 0 0 #fff",
    background: "transparent",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const LinkItems = styled('a')(({ theme }) => ({
    color: theme.palette.secondary.dark,
    margin: "10px 0",
    width: "100%",
    fontSize: "18px",
    textDecoration: "none",
    display: 'flex',
    alignItems: "center",
    justifyContent: "start",
    fontFamily: "Roboto Condensed",

    "svg": {
        color: theme.palette.primary.main,
        width: "16px",
        height: "16px",
        margin: "5px",
    }
}))
