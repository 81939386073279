import React from "react";
import { TagContainer } from "./elements"

interface TagProps {
    content: string,
    isOver: boolean,
    isTopRight: boolean
}

const CustomTag: React.FC<TagProps> = ({ content, isOver, isTopRight }) => {
    return (
        <TagContainer isOver={isOver} isTopRight={isTopRight}>{content}</TagContainer>
    )
}

export default CustomTag