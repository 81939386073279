import styled from "styled-components"

export const Wrapper = styled('div')(({ theme }) => ({
    padding: "30px 20px",

    [theme.breakpoints.down("md")]: {
        padding: "20px 10px",
    },
}))

export const WidthWrapper = styled('div')(({ theme }) => ({
    width: "95%",
    display: "block",
    margin: "0 auto",
}))

export const ColumnLayout = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    marginTop: "80px",

    [theme.breakpoints.down("md")]: {
        alignItems: "center",
        marginTop: "50px",
    },
}))

export const MintPriceLayout = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",

    [theme.breakpoints.down("md")]: {
        flexDirection: "column",

    },
}))