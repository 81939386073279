import React, { useState, useCallback } from "react"
import { Box } from "@mui/material";
import { MintPriceLayout } from "../../Layout";
import { BuyButton } from "../../Button";
import { CardText } from "../../Text";
import { useBoomgalaContract } from "../../../hooks/useContract";
import { getFullDisplayBalance } from "../../../utils/format";
import CustomSnackbar from "../../Snackbar";
import { numberMapping } from "../../../utils/valueMapping";
import BigNumber from "bignumber.js";
import { MintIcon } from "../elements";

interface MintSectionProps {
    mintNumber: number,
    price: string,
    open: boolean,
    wlSaleStarted?: boolean
    isWhiteList?: boolean,
    isStock: boolean
}

const MintSection: React.FC<MintSectionProps> = ({ mintNumber, price, wlSaleStarted, open, isWhiteList, isStock }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [loading, setLoading] = useState(false)
    const boomgalaContract = useBoomgalaContract()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [transcationHash, setTransactionHash] = useState<string>("")
    const [loadingMessage, setLoadingMessage] = useState<string>("")
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")

    const mintNft = useCallback(async () => {
        setLoading(true)
        setOpenSnackbar(true)
        setSuccess(false)
        setError(false)
        setLoadingMessage("")
        setSuccessMessage("")
        setErrorMessage("")
        setTransactionHash("")

        const fullDisplayAmount = getFullDisplayBalance(new BigNumber(price))

        try {
            setLoadingMessage(`Minting ${mintNumber} BoomGala NFT ...`)
            let result: any;

            if (wlSaleStarted && open) {
                result = await boomgalaContract?.wlSaleMint(mintNumber, { value: fullDisplayAmount })
            } else {
                result = await boomgalaContract?.publicSaleMint(mintNumber, { value: fullDisplayAmount })
            }

            if (result) {
                setTransactionHash(result.hash)
                setSuccess(true)
                setLoading(false)
                setSuccessMessage(`Congrats! You have received ${mintNumber} NFT(s). `)
            }
        } catch (error: any) {
            setError(true)
            setLoading(false)
            setErrorMessage(`Oops! Failed to mint BOOMGALA NFTs.`)
        }
    }, [boomgalaContract, mintNumber, open, price, wlSaleStarted])

    return (
        <>
            <MintPriceLayout>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    {
                        Array.from(Array(mintNumber).keys()).map((item: number) => (
                            <MintIcon src="./images/home/mintIcon.svg" key={item}></MintIcon>
                        ))
                    }

                    <CardText isTitle={false} fontSize="25px" fontWeight={500} lineHeight="20px" margin="0px" isUpperCase={true}>{numberMapping[mintNumber]}</CardText>
                </Box>

                <BuyButton onClick={mintNft} disabled={(!isWhiteList && wlSaleStarted) || !isStock}>
                    <CardText isTitle={false} fontSize="20px" fontWeight={700} lineHeight="20px" margin="10px 0" isUpperCase={true} style={{ color: "#fff" }}>{price} ETH</CardText>
                    <CardText isTitle={false} fontSize="20px" fontWeight={700} lineHeight="20px" margin="10px 0" isUpperCase={true}>Buy</CardText>
                </BuyButton>
            </MintPriceLayout>

            <CustomSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                loading={loading}
                loadingMessage={loadingMessage}
                success={success}
                successMessage={successMessage}
                error={error}
                errorMessage={errorMessage}
                transactionHash={transcationHash}
            />
        </>
    )
}

export default MintSection