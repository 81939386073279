import React, { useState, useEffect } from "react"
import Dialog from '@mui/material/Dialog';
import { IconButton, Grid, Box } from "@mui/material";
import { Wrapper, WidthWrapper, ColumnLayout } from "../../Layout";
import { StyledH2, CardText } from "../../Text";
import useWindowWidth from "../../../hooks/useWindowWidth";
import CustomSnackbar from "../../Snackbar";
import { BoomgalaSalesInfo } from "../../../hooks/types";
import CustomTag from "../../Tag";
import { CloseIcon, MintImg, PriceBg } from "../elements";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import useActiveWeb3React from "../../../hooks/useActiveWeb3React";
import { RulesText } from "../../Text";
import MintSection from "./MintSection";
import { CgClose } from 'react-icons/cg';

interface MintDialogProps {
    open: boolean,
    onClose: () => void,
    salesInfo?: BoomgalaSalesInfo
    isWhiteList?: boolean
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MintDialog: React.FC<MintDialogProps> = ({ open, onClose, salesInfo, isWhiteList }) => {
    const isVisible = useWindowWidth(900)
    const [salesPrice, setSalesPrice] = useState<number>(0)
    const [maxMintAmount, setMaxMintAmount] = useState<number>(0)
    const [salesPeriod, setSalesPeriod] = useState<string>("")
    const stock = 10000 - Number(salesInfo?.totalSupply)
    const wlSaleStarted = salesInfo?.wlSaleStarted
    const isStock = stock > 0
    const [error, setError] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const { account } = useActiveWeb3React()

    useEffect(() => {
        if (open) {
            if (salesInfo?.wlSaleStarted) {
                setSalesPeriod("PreSale")
                setSalesPrice(salesInfo.wlPrice)
                setMaxMintAmount(salesInfo.wlMaxAmount)
            } else if (salesInfo?.publicSaleStarted) {
                setSalesPeriod("Public Sale")
                setSalesPrice(salesInfo.publicPrice)
                setMaxMintAmount(salesInfo.publicMaxAmount)
            } else {
                setSalesPeriod("")
            }
        }
    }, [salesInfo, open])

    useEffect(() => {
        if (open) {
            if (!isWhiteList && account && salesInfo?.wlSaleStarted) {
                setError(true)
                setOpenSnackbar(true)
                setErrorMessage("Oops! You are not whitelisted. Please attend our public sale.")
            } else {
                setError(false)
                setOpenSnackbar(false)
            }
        }
    }, [salesInfo?.wlSaleStarted, isWhiteList, account, open])

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose();
                    }
                }}
                TransitionComponent={Transition}
            >
                <CloseIcon>
                    <IconButton size="large" onClick={onClose} color="secondary">
                        <CgClose />
                    </IconButton>
                </CloseIcon>

                <Wrapper>
                    <WidthWrapper>
                        <ColumnLayout></ColumnLayout>
                        <Grid container spacing={5}>
                            {isVisible && <Grid container item sm={12} md={6} alignItems="center" justifyContent="center">
                                <MintImg src="./images/home/mint-shadow.png"></MintImg>
                            </Grid>}

                            <Grid container item sm={12} md={6} alignItems="center" justifyContent="center">
                                <ColumnLayout style={{ margin: "20px 0" }}>
                                    <StyledH2>Mint Now
                                        <CustomTag content={salesPeriod} isOver={false} isTopRight={true} />
                                    </StyledH2>

                                    <Box sx={{ margin: "20px 0" }}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "40px"
                                        }}>
                                            <CardText isTitle={false} fontSize="25px" fontWeight={700} lineHeight="20px" margin="10px 0" isUpperCase={true}>Price: </CardText>
                                            <PriceBg width="200px">{salesPrice}</PriceBg>
                                            <CardText isTitle={false} fontSize="25px" fontWeight={700} lineHeight="20px" margin="10px 0" isUpperCase={true}>ETH</CardText>
                                        </Box>

                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "start",
                                            marginBottom: "20px"
                                        }}>
                                            <CardText isTitle={false} fontSize="25px" fontWeight={700} lineHeight="20px" margin="10px 0" isUpperCase={true}>Stock: </CardText>
                                            <PriceBg width="75px">{stock}</PriceBg>
                                            <CardText isTitle={false} fontSize="25px" fontWeight={700} lineHeight="20px" margin="10px 0" isUpperCase={true}>/ 10000</CardText>
                                            {!isStock && <CustomTag content="Sold Out" isOver={true} isTopRight={false} />}
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        margin: "10px 0",
                                    }}>{
                                            Array.from(Array(maxMintAmount).keys()).reverse().map((item: number) => (
                                                <MintSection
                                                    mintNumber={item + 1}
                                                    price={((item + 1) * salesPrice).toFixed(1)}
                                                    key={item}
                                                    open={open}
                                                    isWhiteList={isWhiteList}
                                                    wlSaleStarted={wlSaleStarted}
                                                    isStock={isStock}
                                                ></MintSection>
                                            ))
                                        }
                                    </Box>

                                    <ul style={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "start",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}>
                                        <CardText isTitle={false} fontSize="25px" fontWeight={700} lineHeight="20px" margin="5px 0" isUpperCase={true}>* rules:</CardText>
                                        <RulesText>Each wallet address can mint up to 2 BOOMGALA NFTS at PreSale Stage.</RulesText>
                                        <RulesText>Each wallet address can mint up to 3 BOOMGALA NFTS at Public Sale Stage.</RulesText>
                                        <RulesText>Each wallet address can hold up to 5 BOOMGALA NFTS.</RulesText>
                                    </ul>
                                </ColumnLayout>
                            </Grid>
                        </Grid>
                    </WidthWrapper>
                </Wrapper>
            </Dialog>

            <CustomSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                error={error}
                errorMessage={errorMessage}
            ></CustomSnackbar>
        </>
    )
}

export default MintDialog