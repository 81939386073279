import React from "react";
import { CardText } from "../Text";
import { Box } from "@mui/system";
import { CardBox, CardImg } from "./elements"

interface TeamCardProps {
    name: string,
    role: string,
    portrait: string,
    description: string
}

const TeamCard: React.FC<TeamCardProps> = ({ name, role, portrait, description }) => {
    return (
        <CardBox>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "85%",
            }}>
                <CardText isTitle={true} fontSize="40px" fontWeight={700} lineHeight="56px" margin="10px 0" isUpperCase={true}>{name}</CardText>
                <CardText isTitle={false} fontSize="18px" fontWeight={300} lineHeight="28px" margin="10px 0" isUpperCase={true}>{role}</CardText>
            </Box>

            <CardImg src={portrait}></CardImg>
            <div style={{ width: "85%" }}>
                <CardText isTitle={false} fontSize="20px" fontWeight={700} lineHeight="25px" margin="20px 0" dangerouslySetInnerHTML={{ __html: description }}></CardText>
            </div>

        </CardBox>
    )
}

export default TeamCard