import React, { useCallback } from "react";
import { Dialog, Box } from "@mui/material";
import { TeamDialogCloseIcon } from "../elements";
import { IconButton } from "@mui/material";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { shortenAddress } from "../../../functions/format";
import { ConnectButton } from "../../Button";
import useAuth from "../../../hooks/useAuth";
import LinkEnternal from "../../LinkExternal";
import { CardText } from "../../Text";
import { getAddressOnScan } from "../../../utils/getChainInfo";
import { CgClose } from 'react-icons/cg';
import { AiOutlineCopy } from "react-icons/ai"

const chainId = Number(process.env.REACT_APP_CHAIN_ID)

interface AccountDialogProps {
    open: boolean,
    onClose: () => void,
    account?: string | null
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AccountDialog: React.FC<AccountDialogProps> = ({ open, onClose, account }) => {
    const { logout } = useAuth()

    const disconnect = useCallback(() => {
        logout()
        onClose()
    }, [logout, onClose])

    const copy = useCallback(() => {
        navigator.clipboard.writeText(JSON.stringify(account));
    }, [account])

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            TransitionComponent={Transition}
            PaperProps={{
                style: { borderRadius: 20 }
            }}
        >
            <TeamDialogCloseIcon>
                <IconButton size="large" onClick={onClose} color="secondary">
                    <CgClose />
                </IconButton>
            </TeamDialogCloseIcon>

            <Box sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                flexDirection: "column",
                padding: "5px 20px"
            }}>
                <CardText isTitle={true} fontSize="30px" fontWeight={700} lineHeight="56px" margin="0" isUpperCase={true}>Your Wallet</CardText>

                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "250px",
                }}>
                    <CardText isTitle={false} fontSize="20px" fontWeight={300} lineHeight="28px" margin="5px 0" isUpperCase={false}>{account && shortenAddress(account)}</CardText>
                    <IconButton onClick={copy} sx={{ cursor: "copy" }}>
                        <AiOutlineCopy></AiOutlineCopy>
                    </IconButton>
                </Box>

                <LinkEnternal href={getAddressOnScan(chainId, String(account))} text="View on Etherscan"></LinkEnternal>

                <ConnectButton onClick={disconnect}>Disconnect</ConnectButton>
            </Box>
        </Dialog>
    )

}

export default AccountDialog