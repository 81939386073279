import styled from "styled-components";
import {
    TimelineItem,
    Timeline,
} from '@mui/lab';

export const StyledTimeline = styled(Timeline)(({ theme }) => ({
    width: "100%",
    display: "block",
    margin: "0 auto",
    color: theme.palette.primary.main,
}))

export const RoadmapContainer = styled("div")(({ theme }) => ({
    padding: "0 px",

    [theme.breakpoints.down("md")]: {
        padding: "10px 0",
    },
}))

export const RoadmapIcon = styled('img')(({ theme }) => ({
    width: "20px",
    height: "20px"
}))

export const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
    "::before": {
        content: "none"
    }
}))