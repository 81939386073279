import styled from "styled-components";

export const HeroText = styled('div')(({ theme }) => ({
    fontFamily: "Road Rage",
    color: theme.palette.primary.main,
    fontSize: "120px",
    fontWeight: 400,
    lineHeight: "150px",
    textTransform: "uppercase",
    padding: "50px",
    width: "80%",

    [theme.breakpoints.between("lg", "xl")]: {
        fontSize: "100px",
        lineHeight: "120px",
        width: "90%",
    },

    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "85px",
        lineHeight: "100px",
        width: "95%",
    },

    [theme.breakpoints.down("md")]: {
        display: "none"
    },
}))

export const StyledH2 = styled('h2')(({ theme }) => ({
    fontFamily: "Road Rage",
    fontWeight: 400,
    fontSize: "40px",
    lineHeight: "65px",
    color: theme.palette.secondary.dark,
    textTransform: "uppercase",
    marginBottom: "20px",

    [theme.breakpoints.down("md")]: {
        fontSize: "30px",
        marginBottom: "10px",
    },
}))

export const DisabledNavText = styled('sup')(({ theme }) => ({
    fontFamily: "Roboto Condensed",
    fontSize: "12px",
    color: theme.palette.secondary.dark,
    margin: "0 10px",
}))

export const CardText = styled('p')<{ isTitle: boolean, fontWeight: number, margin: string, fontSize: string, lineHeight: string, isUpperCase?: boolean }>(({ theme, isTitle, fontWeight, fontSize, margin, lineHeight, isUpperCase }) => ({
    fontFamily: `${isTitle ? "Roboto Condensed Bold" : "Roboto Condensed"}`,
    fontSize,
    color: `${isTitle ? theme.palette.primary.main : theme.palette.secondary.dark}`,
    textTransform: `${isUpperCase ? "uppercase" : "none"}`,
    fontWeight,
    lineHeight,
    margin
}))

export const CountdownText = styled('div')(({ theme }) => ({
    fontFamily: "Road Rage",
    fontSize: "65px",
    color: theme.palette.secondary.dark,
    lineHeight: "80px",
    transform: "rotate(-6.5deg) translate(-5%, 0%);",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    padding: "10px",
    minWidth: "700px",
    maxWidth: "750px",

    [theme.breakpoints.down("md")]: {
        fontSize: "30px",
        lineHeight: "40px",
        minWidth: "300px",
        maxWidth: "350px",
        transform: "rotate(-6.5deg) translate(-10%, -30%);",
    },
}))

export const RulesText = styled('li')(({ theme }) => ({
    fontFamily: "Roboto Condensed",
    fontSize: "18px",
    color: theme.palette.secondary.dark,
    margin: "5px 15px",
}))