import styled from "styled-components";

export const TagContainer = styled('sup')<{ isOver: boolean, isTopRight: boolean }>(({ theme, isOver, isTopRight }) => ({
    fontFamily: "Roboto Condensed",
    background: `${isOver ? "rgba(220, 53, 69,0.92)" : "rgba(39, 232, 139, 0.92)"}`,
    borderRadius: "3px",
    fontSize: "18px",
    padding: "5px",
    color: theme.palette.secondary.light,
    verticalAlign: `${isTopRight ? "super" : "baseline"}`,
    marginLeft: "20px",
    textTransform: "uppercase",
}))