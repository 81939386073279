import { NavLinkConfig, SocialLinkConfig } from "../types"
import { getOpenSeaUrl } from "../../utils/getChainInfo"

const chainId = Number(process.env.REACT_APP_CHAIN_ID)

export const navLinks: NavLinkConfig[] = [
    {
        name: "Home",
        link: "/",
        isAvailable: true
    },

    {
        name: "Team",
        link: "/team",
        isAvailable: true
    },

    {
        name: "Gallery",
        link: "/gallery",
        isAvailable: false
    },

    {
        name: "Manifesto",
        link: "/manifesto",
        isAvailable: true
    },
]

export const socialLinks: SocialLinkConfig[] = [
    {
        name: "discord",
        link: "https://discord.gg/boomgalaofficial",
        img: "./images/discord.svg",
        smImg: "./images/discord-sm.svg"
    },

    {
        name: "twitter",
        link: "https://twitter.com/Boomgala2022",
        img: "./images/twitter.svg",
        smImg: "./images/twitter-sm.svg"
    },

    {
        name: "opensea",
        link: getOpenSeaUrl(chainId),
        img: "./images/opensea.svg",
        smImg: "./images/opensea-sm.svg",
    },

    {
        name: "instagram",
        link: "https://instagram.com/boomgala2022?utm_medium=copy_links",
        img: "./images/instagram.svg",
        smImg: "./images/instagram-sm.svg",
    }
] 
