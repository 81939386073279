import React from "react"
import { CgClose } from 'react-icons/cg';
import { SnackbarProps } from "./types";
import { SnackbarContainer, CloseButton, IconContainer, SnackbarText, StyledSnackbar } from "./elements";
import CircularProgress from '@mui/material/CircularProgress';
import { BiErrorCircle } from "react-icons/bi"
import { FaCheckCircle } from "react-icons/fa"
import { Box } from "@mui/system";
import { getTranscationHash } from "../../utils/getChainInfo";
import { FiExternalLink } from "react-icons/fi"

const chainId = Number(process.env.REACT_APP_CHAIN_ID)

const CustomSnackbar: React.FC<SnackbarProps> = ({
    open,
    onClose,
    loading,
    loadingMessage,
    success,
    successMessage,
    error,
    errorMessage,
    transactionHash,
}) => {
    const handleClose = (
        event: Event | React.SyntheticEvent,
        reason?: string,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose()
    };

    let comp;

    if (loading) {
        comp = <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "300px",
        }}>
            <CircularProgress size="25px" sx={{ margin: "0px 5px" }} />
            <SnackbarText>{loadingMessage}</SnackbarText>
        </Box>
    } else {
        if (success) {
            comp = <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "300px",
            }}>
                <IconContainer color="#00FF00">
                    <FaCheckCircle />
                </IconContainer>

                <SnackbarText>
                    {successMessage}
                </SnackbarText>

                <CloseButton onClick={() => window.open(getTranscationHash(chainId, transactionHash), '_blank')}>
                    <FiExternalLink />
                </CloseButton>
            </Box >
        } else if (error) {
            comp = <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                width: "300px",
            }}>
                <IconContainer color="#dc3545">
                    <BiErrorCircle />
                </IconContainer>

                <SnackbarText>
                    {errorMessage}
                </SnackbarText>
            </Box >
        }
    }

    return (
        <>
            <StyledSnackbar
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SnackbarContainer>
                    {comp}
                    <CloseButton onClick={handleClose} >
                        <CgClose />
                    </CloseButton>
                </SnackbarContainer>
            </StyledSnackbar>
        </>
    );
}

export default CustomSnackbar