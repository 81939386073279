import React, { useState, useEffect } from "react"
import { ConnectButton } from "../Button";
import { ConnectorNames } from "../../utils/web3React"
import { UnsupportedChainIdError } from "@web3-react/core";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import useAuth from "../../hooks/useAuth";
import { WalletImage } from "./elements";
import CustomSnackbar from "../Snackbar";
import { shortenAddress } from "../../functions/format";
import AccountDialog from "../Dialog/AccountDialog";
import { Divider, Popover } from "@mui/material";

const UnlockButton: React.FC = () => {
    const { login, logout } = useAuth()
    const { account, error } = useActiveWeb3React();
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (error instanceof UnsupportedChainIdError) {
            setOpenSnackbar(true);
            setErrorMessage("Oops! Please switch to MAINNET");
            logout()
        }
    }, [error, logout])

    return (
        <>
            {
                account ? <ConnectButton onClick={() => setOpenDialog(true)}>
                    {shortenAddress(account)}
                </ConnectButton> :
                    <ConnectButton onClick={handleClick}>
                        Connect
                    </ConnectButton>
            }

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

                sx={{
                    marginTop: "15px",
                    width: "100%",
                }}

                PaperProps={{
                    style: { borderRadius: 7 }
                }}
            >
                <WalletImage src="./images/wallet/metamask.png" onClick={() => {
                    login(ConnectorNames.Injected)
                    handleClose()
                }}></WalletImage>
                <Divider></Divider>
                <WalletImage src="./images/wallet/walletconnect.png" onClick={() => {
                    login(ConnectorNames.WalletConnect)
                    handleClose()
                }}></WalletImage>
            </Popover>

            <CustomSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                error={true}
                errorMessage={errorMessage}
            />

            <AccountDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                account={account}
            />
        </>

    )
}

export default React.memo(UnlockButton)