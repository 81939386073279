import React from "react"
import { Suspense } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import Routes from './routes';
import NavBar from "./components/NavBar";
// import useEagerConnect from "./hooks/useEagerConnect";
// import Footer from "./components/BottomBar";

const App: React.FC = () => {
  // useEagerConnect()

  return (
    <Router>
      <Suspense fallback={null}>
        <NavBar />
        <Routes />
      </Suspense>
    </Router >
  )
}



export default App;
