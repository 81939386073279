import styled from "styled-components";
import { ColumnLayout } from "../../components/Layout"

export const TeamImg = styled('img')(({ theme }) => ({
    width: "75%",
    borderRadius: "50%",
    margin: "10px 0",

    [theme.breakpoints.between("lg", "xl")]: {
        width: "100%",
    },

    [theme.breakpoints.down("md")]: {
        width: "100%",
        cursor: "pointer",
    },
}))

export const CustomColumLayout = styled(ColumnLayout)(({ theme }) => ({
    margin: "0px",
}))