import React from "react";
import { Box } from "@mui/system";
import { BoomBg } from "./elements";
import { StyledH2 } from "../../components/Text";
import { CardText } from "../../components/Text";

const Boom: React.FC = () => {
    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            alignItems: "start",
        }}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "20px 0",
                padding: "0 10px"
            }}>
                <StyledH2 style={{ margin: "0 10px" }}>It's Time to</StyledH2>
                <BoomBg>
                    <CardText isTitle={true} isUpperCase={true} fontSize="25px" margin="0px" fontWeight={700} lineHeight="15px" style={{ color: "#FFF" }}>BOOM!</CardText>
                </BoomBg>
            </Box>
        </Box>
    )
}

export default Boom