import React from "react";
import { WidthWrapper } from "../../components/Layout";
import BottomBar from "../../components/BottomBar";
import { ManifestoBg, CustomWrapper, CustomColumLayout } from "./elements";
import { Grid } from "@mui/material";
import Roadmap from "../../components/Roadmap";
import Intro from "./Intro";
import Boom from "./Boom";

const Manifesto: React.FC = () => {
    return (
        <>
            <ManifestoBg>
                <Grid container spacing={0} sx={{ height: "100%" }}>
                    <Grid container item xs={0} md={7} alignItems="center" justifyContent="center">
                    </Grid>

                    <Grid container item xs={0} md={5} alignItems="center" justifyContent="center">
                        <CustomWrapper>
                            <WidthWrapper>
                                <CustomColumLayout>
                                    <Intro />
                                    <Roadmap />
                                    <Boom />
                                </CustomColumLayout>
                            </WidthWrapper>
                        </CustomWrapper>
                    </Grid>
                </Grid>
            </ManifestoBg>



            <BottomBar isMintButton={false}></BottomBar>
        </>
    )
}

export default Manifesto