import React from "react";
import { StyledIconLink } from "../LinkExternal/elements";
import { SocialImg, SocialIconBox } from "./elements";
import { socialLinks } from "../../config/constants/links";
import useWindowWidth from "../../hooks/useWindowWidth";

const SocialIcons: React.FC = () => {
    const isWeb = useWindowWidth(900);

    return (
        <SocialIconBox>
            {
                socialLinks.map(item => (
                    <StyledIconLink key={item.name} target="_blank" href={item.link}>
                        <SocialImg src={isWeb ? item.img : item.smImg}></SocialImg>
                    </StyledIconLink>
                ))
            }
        </SocialIconBox>
    )
}

export default SocialIcons;