import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { setupNetwork } from '../utils/wallet'
import { connectorsByName, ConnectorNames } from '../utils/web3React'

const connectorLocalStorageKey = "walletconnect";

const useAuth = () => {
    const { activate, deactivate } = useWeb3React()
    const chainId = Number(process.env.REACT_APP_CHAIN_ID)

    const login = useCallback(
        (connectorID: ConnectorNames) => {
            const connector = connectorsByName[connectorID]

            if (connector) {
                activate(connector, async (error: Error) => {
                    if (error instanceof UnsupportedChainIdError) {
                        const hasSetup = await setupNetwork(chainId)
                        if (hasSetup) {
                            activate(connector)
                        }
                    } else {
                        window.localStorage.removeItem(connectorLocalStorageKey);
                        if (error instanceof NoEthereumProviderError) {
                            alert('No provider was found. Please install wallet first')
                        } else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if (connector instanceof WalletConnectConnector) {
                                const walletConnector = connector as WalletConnectConnector
                                walletConnector.walletConnectProvider = null
                            }

                            console.error("An unknown error occurred. Check the console for more details.");
                        }
                    }
                })
            } else {
                console.error('Unable to find connector, the connector config is wrong')
            }
        },
        [activate, chainId]
    )

    const logout = useCallback(() => {
        deactivate()
        if (window.localStorage.getItem(connectorLocalStorageKey)) {
            connectorsByName.walletconnect.close()
            connectorsByName.walletconnect.walletConnectProvider = null
        }
    }, [deactivate])

    return { login, logout }
}

export default useAuth
