import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ethers } from 'ethers'
import getRpcUrl from './getRpcUrl'

const POLLING_INTERVAL = 12000
const rpcUrl = getRpcUrl()
const chainId = Number(process.env.REACT_APP_CHAIN_ID)

export const injected = new InjectedConnector({ supportedChainIds: [chainId] })

export const walletconnect = new WalletConnectConnector({
    rpc: { [chainId]: rpcUrl },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
})

export enum ConnectorNames {
    Injected = 'Injected',
    WalletConnect = "walletconnect",
}

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
}

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = POLLING_INTERVAL
    return library
}