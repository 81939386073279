import React from 'react'

const ContdownDialogContext = React.createContext<any>(null)

const ContdownDialogContextProvider = ({ children }: any) => {
    const [openCountdownDialog, setOpenCountdownDialog] = React.useState<boolean>(false)

    return <ContdownDialogContext.Provider value={{ openCountdownDialog, setOpenCountdownDialog }}>{children}</ContdownDialogContext.Provider>
}

export { ContdownDialogContext, ContdownDialogContextProvider }